.modal-image-wrapper {
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
  padding-right: 15px;
}

#replace-modal{
  .react-bootstrap-table{
    .table{
      th, td, .selection-cell-header, .selection-cell{
        vertical-align: middle;
      }
    }
  }
}

#payment-modal{
  font-weight: 500;
  .payment-modal-wrapper{
    padding: 1rem;
    .payment-modal-border{
      border: 2px solid black;
    }
    .payment-modal-header{
      @extend .display-flex;
      border-bottom: 1px solid black;
    }
    .payment-modal-body{
      .payment-modal-body-header{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        border-bottom: 1px solid black;
        border-top: 1px solid black;
      }
      .product-item{
        &:nth-child(2){
          border-top: 1px solid black;
        }
        border-bottom: 1px solid black;
      }
    }
    .payment-modal-footer{
      .signature-body{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .sign-item{
          border-right: 1px solid black;
          &:last-child{
            border-right: unset;
          }
          .sign-item-title{
            border-bottom: 1px solid black;
            border-top: 1px solid black;
          }
          text-align: center;
          .sign-item-body{
            display: grid;
            place-items: center;
            min-height: 60px;
          }
        }
      }
    }
  }
}

.modal-list-error{
  height: 50vh;
  overflow-y: scroll;
}

.modal-upload-wrapper {
  min-height: 60vh;

  .card-files-section {
    max-height: 32vh;
    min-height: 32vh;
    overflow-y: scroll;
    padding-right: 15px;
  }

  .btn-action-wrapper {
    margin-bottom: 15px;
  }
}

.modal-action-wrapper {
  text-align: center;

  p {
    margin-bottom: 0px;
  }

  .title {
    font-weight: 700;
  }
}

.modal-md{
  .modal-dialog{
    @include media-breakpoint-up(sm){
      max-width: 450px;
    }
  }
}

.modal-incoming-order {
  .title {
    font-weight: 700;
  }
  .label {
    font-weight: 600;
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 2rem;
  }
  .price {
    font-weight: 600;
    color: $primary;
  }
}
