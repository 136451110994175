// Default Page Layout
.default-page-layout {
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-gap: 20px;
  grid-auto-rows: auto;

  &.layout-reverse {
    grid-template-columns: 4fr 8fr;
  }

  &.layout-full{
    grid-template-columns: 1fr;
  }

  .layout-content {
    display: grid;
    grid-gap: 20px;
    grid-auto-rows: auto;
  }

  .floating-content {
    position: sticky;
    position: -webkit-sticky;
    top: 80px;
  }

  &.type-profile {
    .card-files-section {
      &.single-file {
        justify-content: center;
      }
    }
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;

    &.layout-reverse {
      grid-template-columns: 1fr;
    }

    &.type-profile {
      display: flex;
      flex-direction: column;
    }
  }
}

.timeline {
  border-left: 1px solid black;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(255, 255, 255, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 40%;
}

.d-none{
  display: none;
}

@media (max-width: 767px) {
  .timeline {
      max-width: 98%;
      padding: 25px;
  }
}

.timeline h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline .event {
      padding-top: 30px;
  }
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline .event:before {
      left: 0px;
      text-align: left;
  }
}

.timeline .event:after {
  -webkit-box-shadow: 0 0 0 2px black;
  box-shadow: 0 0 0 1px black;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline .event:after {
      left: -31.8px;
  }
}

.rtl .timeline {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline .event::after {
  left: 0;
  right: -55.8px;
}

.page-header{
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  .btn-wrapper{
    @extend .display-flex;
    align-items: center;
    justify-content: center;
    .btn-primary{
      margin-left: 16px;
    }
  }
  &.type-form{
    justify-content: unset;
    .icon-wrapper{
      padding: 6px 10px 6px 8px;
      display: flex;
      border-radius: 50%;
      border: 2px solid #101C51;
      .icon{
        font-size: 16px;
        color: #101C51;
      }
    }

    .title{
      margin-left: 20px;
      margin-bottom: 0;
    }
  }
}

.multiple-paper-section {
  .card-paper {
    margin-bottom: 20px;
  }
}

.status-wrapper {
  padding: 0 5px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  &.for-status-po{
    padding: .3rem .6rem;
    font-size: 12px;
  }

  &.for-status-po-detail{
    width: unset;
    padding: .3rem .6rem;
    font-size: 12px;
  }

  &.type-red {
    color: #AA0000;
    background-color: #F2D9D9;
  }

  &.type-green {
    color: #045D00;
    background-color: #D9E7D9;
  }

  &.type-blue {
    color: rgb(19, 146, 204);
    background-color: #b5d1ec;
  }

  &.type-yellow {
    color: rgb(255, 116, 116);
    background-color: #ffccca;
  }
  &.type-grey{
    color: white;
    background-color: #bababa;
  }

}
