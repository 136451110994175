.daterangepicker {
  &.show-calendar {
    .drp-buttons {
      @extend .display-flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &.auto-apply {
    .drp-buttons {
      display: none;
    }
  }

  .drp-buttons .btn {
    min-height: unset;
  }

  td {
    &.active {
      background: $primary;

      &:hover {
        background: $primary;
      }
    }
  }
}

.table {
  .table-btn-wrapper {
    @extend .display-flex;
    align-items: center;
    justify-content: center;

    .btn {
      margin-right: 5px;
    }
  }

  .table-slot {

    .table-error-icon {
      margin-left: 5px;
    }
  }

  .table-error {
    color: $danger;
  }
}

.table-add-item {
  td {
    vertical-align: sub;
  }

  .close-action {
    width: 25px;
  }
}

.table-fixed {
  table-layout: fixed;
}



.react-bootstrap-table {
  .table {
    td {
      word-break: break-all;
    }

    @include media-breakpoint-down(sm){
      td{
        word-break: unset;
      }
    }

    .dropup {
      .caret {
        content: '';
        border-top: 0;
        border-bottom: 4px dashed;
      }
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    .product-info {
      margin-bottom: 10px;

      p {
        margin-bottom: 0px;
      }

      label {
        font-size: 12px;
      }
    }

    .table-link {
      cursor: pointer;
      color: $primary;
    }
  }

  .table-loading-section {
    height: 250px;
    @extend .display-flex;
    align-items: center;
    justify-content: center;
  }

  .channel-icon {
    width: 35px;
  }
}

.react-bootstrap-table-pagination {
  margin-top: 1rem;
  .dropdown {
    display: inline-flex;

    .btn {
      min-height: unset;
      margin-right: 10px;
      background: transparent;
      color: $text-color;
      border: 1px solid $border-color;
    }

    .dropdown-menu {
      background: white;

      .dropdown-item {
        padding: 0px;

        a {
          display: block;
          padding: 0.25rem 1rem;
          background: white;
          @include transition(all, 0.5s, ease);

          &:hover {
            background: $gray-200;
          }
        }
      }
    }
  }

  .pagination {
    justify-content: flex-end;

    .page-item {
      &.active {
        .page-link {
          background: $primary;
          color: white;
        }
      }

      .page-link {
        color: $primary;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.tox-tinymce-aux {
  z-index: 1040 !important;
}

.react-bootstrap-table {
  .table {
    table-layout: auto;
    border: 1px solid #aaaaaa;
    margin-bottom: 0;

    th {
      background: #F1F1F1;
    }

    tr, td {
      border-color: #aaaaaa;
    }

    th, td {
      padding: 15px;
      border: none;
      white-space: nowrap;
    }

    .header-info {
      @extend .display-flex;
      align-items: center;

      .icon {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .valign-middle {
      vertical-align: middle;
    }
  }
}

.table-invoice {
  tr {
    vertical-align: middle;
  }
}

.form-check {
  .form-check-label {
    margin-top: 5px !important;
  }
}

a {
  text-decoration: none;
}

.link-primary {
  cursor: pointer;
  color: $primary;
}
