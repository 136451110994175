// Form Element
.form-group {
  margin-bottom: 25px;
  position: relative;

  &.error {
    color: $danger;

    .form-control {
      border-color: $danger;
      color: $danger;
    }

    .label-help {
      color: $danger;
    }

    .input-group-text {
      border-color: $danger;
    }

    .form-select-wrapper {
      .select__control {
        border-color: $danger !important;
      }
    }
  }

  .form-label {
    margin-bottom: 5px;
  }

  .label-help {
    font-size: 12px;
    line-height: 1;
    position: absolute;
    top: calc(100% + 2px);
  }
}

.th-permission {
  text-transform: uppercase;
  text-align: center;
}

.td-permission {
  text-transform: capitalize;
  &.check {
    text-align: center;
    input {
      width: 15px;
      height: 15px;
    }
  }
}

.form-check-input:checked[type="checkbox"] {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.form-check {
  @extend .display-flex;
  align-items: center;
  cursor: pointer;

  .form-check-input {
    padding: 0.5em;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    border: 2px solid #adb5bd;
    cursor: pointer;
  }
  .form-check-label {
    margin: 0px;
    line-height: 1;
    cursor: pointer;
  }
}

.form-control {
  border-color: $gray-500;

  &:focus {
    border-color: $gray-500;
    box-shadow: none;
  }

  &.type-small {
    font-size: 14px;
    line-height: 1;
  }
}

.form-select {
  border-color: $gray-500;

  &:focus {
    border-color: $gray-500;
    box-shadow: none;
  }
}

.form-check-input {
  border-color: $gray-500;

  &:checked {
    background: $primary;
    border-color: $primary;
  }

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }
}

.input-group {
  &.prefix-group {
    .input-group-text {
      padding-right: 0px;
    }

    .form-control {
      border-left: none;
    }
  }

  &.suffix-group {
    .input-group-text {
      padding-left: 0px;
    }

    .form-control {
      border-right: none;
    }
  }

  .input-group-text {
    background: transparent;
    border-color: $gray-500;
  }

  .form-control {
    border-color: $gray-500;
  }
}

.form-btn-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    min-width: 100px;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.form-select-wrapper {
  .select__control {
    border-color: $gray-500 !important;
    box-shadow: none;
    flex-wrap: nowrap;
  }

  .select__menu {
    z-index: 2;

    .select__option {
      color: $text-color;

      &.select__option--is-focused {
        background: $gray-300;
      }

      &.select__option--is-selected {
        color: white;
        background: $primary;
      }

      &.select__option--is-disabled {
        opacity: 0.5;
      }
    }
  }
}

.form-with-button-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;

  .form-input-wrapper {
    flex: 1;
  }
}
// End Form Element
