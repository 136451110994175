button {
  &:focus {
    outline: none;
  }
}

.btn-sm-full-width{
  @include media-breakpoint-down(sm){
    width: 100%;
  }
}

.btn-sm-not-full-width{
  @include media-breakpoint-down(sm){
    width: unset;
  }
}

.btn {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  min-height: 38px;
  @include transition(all, 0.5s, ease);

  a {
    color: inherit;
  }

  &:hover {
    a {
    }
  }

  &.btn-hidden{
    visibility: hidden;
    right: 100px;
    position: absolute;
    z-index: -1;
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none !important;
    outline: none;

    a {
    }
  }

  &:disabled,
  &.disabled {
    background: $gray-600;

    a {
    }
  }

  .loader-wrapper {
    position: relative;
    padding-left: 0px;
    @include transition(all, 0.3s, ease);

    &.loading {
      padding-right: 25px;

      .spinner-border {
        visibility: visible;
        opacity: 1;
        letter-spacing: 0px;
        right: 0;
      }
    }

    .spinner-border {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 10px;
      visibility: hidden;
      opacity: 0;
      @include transition(all, 0.5s, ease);
    }
  }

  .icon {
    &.icon-prefix {
      margin-right: 10px;
    }
  }

  .btn-text {
    position: relative;
  }
}

.btn-primary {
  background: $primary;
  color: white;

  @include media-breakpoint-down(sm) {
    //&.btn-small{}
    width: 100%;
  }
  &:hover,
  &:focus,
  &:active {
    background: $primary-dark;
    color: white;

    a {
      color: white;
    }
  }
}

.btn-secondary {
  &:hover,
  &:focus,
  &:active {
  }
}

.btn-full-width {
  width: 100%;
}

.btn-small {
  font-size: 12px;
  min-height: unset;
}

.btn-orange {
  background: $orange;
  color: white;

  &:hover,
  &:focus,
  &:active {
    background: $orange;
    color: white;
  }
}

.default-btn-action-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  &.type-start{
    justify-content: unset;
  }

  .btn {
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }
  }
}
