// Main Component
.card-paper-shadow {
  background: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
}

.font-size-3{
  font-size: 0.75rem;
}

.variant-unit-wrapper {
  @extend .display-flex;
  .variant-unit {
    margin-right: 8px;
    .form-check .form-check-label {
      margin-top: 0 !important;
    }
  }
}

.voucher-text{
  font-weight: 600;
  font-size: 16px;
}

.table-report {
  tbody {
    td {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .table-idr {
    padding-right: 40px;
    font-weight: bold;
  }

  .table-head {
    color: $main-bg;
  }
  .title-table {
    font-weight: bold;
  }

  .data-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.pad {
      padding-left: 40px;
    }
  }
}

#report-section {
  .card-header {
    .card-header-filter-wrapper {
      @extend .display-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;
    }
  }
}

.form-group {
  textarea {
    resize: none;
  }
}

.image-table-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;

  .card-files-section {
    &.single-file {
      grid-template-columns: minmax(0, 50px);

      .card-file {
        height: 50px !important;

        .card-label {
          display: none;
        }
      }
    }
  }
}

.print-order-wrapper {
  left: 50%;
  position: absolute;
  transform: translate(-50%);
}

.btn-action-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    width: 20px !important;
    height: 20px;
  }
}

hr {
  display: block;
  height: 1px;
  background: transparent;
  width: 100%;
  border: none;
  border-top: solid 1px #aaa;
}

.card-paper {
  @extend .card-paper-shadow;

  .card-header {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-radius: unset;
    padding: 10px 20px;
    min-height: 40px;

    .card-header-title {
      margin-bottom: 0px;
      font-weight: 600;
    }
  }

  .card-content {
    padding: 20px;

    &.type-button {
      padding: 20px;
    }
  }

  .card-info {
    margin-bottom: 30px;

    .card-value {
      font-size: 34px;
      font-weight: 600;
    }

    .card-note {
      font-size: 14px;

      .percentage {
        font-weight: 700;
      }

      .icon {
        font-size: 12px;
      }

      .positive {
        color: $success;
      }

      .negative {
        color: $danger;
      }
    }
  }

  .card-filter-section {
    .form-group {
      margin-bottom: 15px;
    }
  }

  .card-footer {
    background: white;
    padding: 10px 20px;

    .default-btn-action-wrapper {
      margin-bottom: 0px;

      .btn {
        min-width: 100px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .card-content {
      padding: 15px;
    }
  }
}

.card-empty-content {
  text-align: center;
  font-weight: 700;
  color: $danger;
}

.summary-data {
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
  h6{
    margin-bottom: 0;
    margin-right: 40px;
  }
}

.default-filter-section {
  @extend .display-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;

  &.type-search {
    display: flex;
    justify-content: flex-end;
  }

  .filter-item {
    margin-right: 15px;
    margin-bottom: 15px;
    flex: 20% 0;
    &.type-search-full {
      margin-right: 0;
      flex: 100% 0;
    }
    &.filter-special {
      flex: 30% 0;

      &.type-search {
        flex: 50% 0;
      }
    }

    @include media-breakpoint-down(md) {
      flex: 100%;
      &.filter-special {
        flex: 100%;

        &.type-search {
          flex: 100%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }
}

.vui-progress-label-component {
  margin-bottom: 15px;
  font-size: 12px;

  .title {
    margin-bottom: 5px;
  }

  .vui-progress-bar {
    height: 10px;
    margin-bottom: 5px;

    .progress-bar {
      background: $primary;
    }
  }
}

.mr-2{
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-unset{
  margin-bottom: unset !important;
}

.page-header-component {
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .title-action-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    flex: 1 1 20%;

    .form-group {
      width: 20%;
      margin-bottom: unset;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .form-group {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;

    .title {
      margin-bottom: 10px;
    }

    .btn-wrapper {
      width: 100%;
      .btn-primary {
        width: 100%;
      }
    }
  }

  .action-wrapper {
    @extend .display-flex;
    align-items: center;

    .action-item {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .action-item {
        margin-right: 0px;
        width: 100%;
      }
    }
  }
}

.card-files-section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;

  &:focus {
    outline: none;
  }

  &.single-file {
    grid-template-columns: minmax(0, 200px);

    .card-file {
      .card-inner {
        .card-filled-wrapper {
          .action-wrapper {
            min-height: 40px;
          }

          .icon-delete-file {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.select-media {
    grid-template-columns: repeat(4, 1fr);
  }

  &.type-left {
    justify-content: flex-start !important;
  }

  &.type-center {
    justify-content: center;
  }

  .card-file {
    border: 1px dashed $gray-500;
    cursor: pointer;
    outline: none;
    background: transparent;
    @include transition(all, 0.5s, ease);
    border-radius: $border-radius;

    &:hover {
      background: $gray-200;
    }

    &.filled {
      border: none;
      background: $gray-200;
    }

    &.select {
      border: 3px solid transparent;

      &:hover {
        border-color: $primary;
      }
    }

    .card-inner {
      @extend .display-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &:focus {
        outline: none;
      }

      .card-label {
        text-align: center;
        margin-bottom: 0px;
      }

      .card-filled-wrapper {
        width: 100%;
        height: 100%;
        @extend .display-flex;
        overflow: hidden;
        position: relative;
        align-items: center;
        border-radius: $border-radius;
        justify-content: center;

        .filled-icon {
          font-size: 32px;
        }

        .image {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
        }

        .info-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background: rgba($primary, 0.5);
          padding: 15px;
          opacity: 0;
          @include transition(all, 0.5s, ease);

          &:hover {
            opacity: 1;
          }

          .info-size {
            font-size: 8px;
            margin-bottom: 5px;
          }

          .info-name {
            text-align: center;
            width: 100%;
            @include ellipsis(1);
          }
        }

        .action-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
          min-height: 30px;

          .icon-delete-file {
            color: $danger;
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
          }

          .action-check {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5%;
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.card-upload-wrapper {
  .upload-dropzone {
    @extend .display-flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    margin-bottom: 15px;
    min-height: 200px;
    border: 1px dashed $gray-500;
    border-radius: $border-radius;
    cursor: pointer;
    outline: none;
    background: transparent;
    @include transition(all, 0.5s, ease);

    &:hover {
      background: $gray-200;
    }

    .icon {
      font-size: 86px;
      margin-right: 15px;
    }

    .title {
      font-weight: 700;
      margin-bottom: 0px;
    }

    .summary {
      margin-bottom: 0px;
    }
  }
}

.button-submit-print-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: auto;
  align-items: center;

  .title {
    font-size: 16px;
  }

  .button-wrapper {
    display: grid;
    grid-template-columns: 115px 115px 115px;
    grid-gap: 20px;
    grid-auto-rows: auto;
    align-items: center;

    .btn {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        width: 100%;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

.card-setting-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: auto;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}

.card-setting {
  background: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  padding: 30px;

  .inner-content {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    align-items: center;

    &.type-channel {
      display: flex;
      justify-content: center;

      .logo {
        width: auto;
        height: 50px;
      }
    }

    .icon {
      &.type-setting {
        height: 50px;
        width: 50px;
      }
    }

    .title {
      font-size: 18.16px;
      margin-bottom: 0px;
      font-weight: 600;
    }
  }
}

.card-action-form {
  @extend .card-paper-shadow;
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-top: 20px;

  .title {
    flex: 1;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .btn-action-wrapper {
    @extend .display-flex;
    grid-gap: 15px;

    .btn {
      min-width: 150px;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: 25px 15px;

    .title {
      margin-bottom: 15px;
    }

    .btn-action-wrapper {
      flex-direction: column;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.card-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  .card-summary-title {
    label {
      display: block;
      font-weight: 600;
    }
  }

  .card-summary-content {
    label {
      display: block;
    }
  }
}

.table-summary {
  color: $text-color;
  table-layout: fixed;
  width: 100%;

  &.type-right {
    text-align: right;

    .form-control {
      text-align: right;
    }

    tr {
      td {
        &:first-child {
          width: 40%;
        }
      }
    }
  }

  tr {
    td {
      padding: 5px 15px;

      &:first-child {
        font-weight: 700;
        padding-left: 0px;
      }

      &:last-of-type {
        padding-right: 0px;
      }
    }
  }
}

.loader-overlay {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
}

.card-chart-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;

  &.three-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  &.type-compact {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);

    &.type-compact {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);

    &.type-compact {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.card-chart {
  padding: 15px;
  box-shadow: 0px 0px 15px -8px #848484;
  cursor: pointer;
  background: white;
  @include transition(all, 0.5s, ease);

  &.active {
    background: #f4f4f4;
    box-shadow: 0px 5px 15px -5px #848484;
  }

  .card-title {
    @extend .display-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 15px;

    .icon {
      margin-left: 5px;
    }
  }

  .card-value {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;

    &.positive {
      color: $success;
    }

    &.negative {
      color: $danger;
    }
  }

  .card-note {
    font-size: 14px;

    .percentage-stock {
      font-size: 28px;
      font-weight: 700;
    }

    .percentage {
      font-weight: 700;
    }

    .icon {
      font-size: 12px;
    }

    .positive {
      color: $success;
    }

    .negative {
      color: $danger;
    }
  }
}

.status-options {
  @extend .default-list-style;
  @extend .display-flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  overflow: auto;

  .status-item {
    cursor: pointer;
    padding: 15px;
    position: relative;
    min-width: 100px;
    text-align: center;

    &.active {
      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 2px;
        width: 100%;
        background: $primary-dark;
      }
    }
  }
}

.inline-button {
  display: flex;
  align-items: center;
  padding-top: 10px;
  grid-gap: 15px;

  .inline-button-submit {
    .btn {
      padding: 5px 50px;
    }
  }

  .btn-secondary {
    color: #707070;
    background-color: transparent;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    width: 100%;

    .inline-button-reset {
      width: 100%;
    }

    .inline-button-submit {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }
}

.product-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  .product-image {
    border-radius: 50%;
    border: 1px solid #707070;
    padding: 10px;
    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }
  }
  .product-name {
  }
  .product-label {
    font-size: 12px;
  }
}

.channel-image-stock {
  max-width: 20px;
}

.form-stock-image {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.switch-wrapper {
  padding-top: 0.5rem;
}

.table-custom {
  position: relative;

  .selection-cell {
    padding-top: 1.65rem !important;
  }

  .td-custom {
    padding-top: 1.65rem !important;
  }

  .expand-cell {
    padding-top: 1.65rem !important;
  }

  .expand-cell-header {
    color: #f1f1f1;
  }

  .expanded-icon {
    padding: 5px;
    display: flex;
    border-radius: 50%;
    border: 2px solid #101c51;
    .icon {
      font-size: 10px;
      color: #101c51;
    }
  }

  .table-radio-input-wrapper {
    padding-top: 0.5rem;
  }

  .form-group {
    margin-bottom: unset;
    padding-top: 0.25rem;
  }

  .input-group {
    padding-top: 0.25rem;
  }

  .form-select-wrapper {
    padding-top: 0.25rem;
  }
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.input-group-prepend {
  .input-group-text {
    background-color: #bababa;
    color: #ffffff;
    font-size: 12px;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group-append {
  .input-group-text {
    background-color: #bababa;
    color: #ffffff;
    font-size: 12px;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.dropdown-select-checkbox {
  .dropdown-control {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 40px;
    border: 1px solid $gray-500;
    border-radius: 3px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $text-color;
    background-color: #fff;
  }

  .dropdown-content {
    z-index: 99;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% - 10px);
    right: 0px;
    border-radius: 5px;
    min-width: 200px;
    box-shadow: $box-shadow;
    padding: 15px 0px;
    font-size: 12px;
    background: white;
    @include transition(all, 0.5s, ease);

    &.show {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 10px);
    }

    .dropdown-header {
      @extend .display-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-400;

      .link-action {
        cursor: pointer;

        &.select {
          color: $primary;
        }

        &.remove {
          color: $danger;
        }
      }
    }

    .dropdown-option-wrapper {
      padding: 10px 15px;
      height: 250px;
      overflow-y: auto;

      .dropdown-options {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
      }
    }
  }

  .form-check {
    .form-check-label {
      margin-top: 0px !important;
    }
  }
}

.vendor-table {
  th {
    background: $gray-200;
  }

  .product-info {
    .name {
      font-weight: 500;
      margin-bottom: 0px;
    }

    table {
      font-size: 12px;
    }
  }

  .total {
    vertical-align: middle;
    text-align: right;
    font-size: 16px;
    font-weight: 700;
  }
}

.timeline-activity {
  .header {
    @extend .display-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: $primary-dark;
    margin-bottom: 15px;

    .icon {
      margin-left: 15px;
    }
  }

  .timeline-wrapper {
    .item {
      @extend .display-flex;
      align-items: center;
      padding-bottom: 30px;

      &:last-of-type {
        .dot {
          &:before {
            display: none;
          }
        }
      }

      .date {
        text-align: right;
        width: 100px;
      }

      .dot {
        height: 50px;
        width: 50px;
        position: relative;

        &.active {
          &:after {
            background: $primary-dark;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: white;
          border: 1px solid $primary-dark;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          height: 150%;
          width: 1px;
          background: $primary-dark;
        }
      }
    }
  }
}

.card-setting-widget-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}

.card-setting-widget {
  background: white;
  border-radius: 0.3rem;
  box-shadow: $box-shadow;
  padding: 10%;
  @extend .display-flex;
  align-items: center;

  .card-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .card-title {
    font-weight: 900;
    font-size: 28px;
    margin-bottom: 5px;
  }

  .card-subtitle {
    font-size: 12px;
  }
}

.card-permission-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.card-permission {
}

.card-vendor-area-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}

.brand-selector-wrapper {
  &.hide-sm {
    display: block;
  }

  &.show-sm {
    display: none;
  }

  @include media-breakpoint-down(md) {
    &.hide-sm {
      display: none;
    }

    &.show-sm {
      display: block;
    }
  }
}
// End Main Component
