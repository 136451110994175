$main-bg: #f4f6f8;
$secondary-bg: #ffffff;

$text-color: #484848;

$primary: #005BA7;
$primary-dark: #101C51;

$un-read: #e6f3ff;

$box-shadow: 0 0 10px -4px rgba(black, 0.3);
