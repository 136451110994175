#setting-section {
  .default-page-layout {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: 20px;
    grid-auto-rows: auto;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }

  .page-header-component {
    .button-custom-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;

      .toggle-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .toggle-title {
          text-align: center;
          padding: 15px;
        }

        .toggle {
          text-align: center;
          padding: 10px 0px;

          @include media-breakpoint-down(md) {
            padding: 10px 25px;
          }
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
          a {
            width: 100%;

            .btn {
              width: 100%;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
